const messages = {
  id: 'ID',
  title: 'Title',
  native: 'Native',
  quality: 'Quality',
  source: 'Source',
  type: 'Type',
  sharedUsers: 'Author ratio',
  publication: 'Publication',
  save: 'Save',
  saveAndAddNew: 'Save and add new',
  rubric: 'Rubric',
  printTitleId: 'Title',
  yes: 'Yes',
  no: 'No',
  filter: {
    search: 'Search',
    reset_filter: 'Reset filter'
  },
  error: {
    required_fields: 'Please fill all required fields: '
  },
  message: {
    updated_record: 'Record updated!',
    deleted_record: 'Record deleted!'
  },
  orderDate: 'Publishing date'
}

export default messages
