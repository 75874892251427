const messages = {
  createModule: 'Create module',
  viewModule: 'View module',
  editModule: 'Edit module',
  moduleId: 'Module ID',
  createdBy: 'Created by (created at)',
  modifiedBy: 'Modified by (modified at)',
  infobox: 'Infobox',
  joke: 'Joke',
  quote: 'Quote',
  poll: 'Poll',
  likeDislike: 'Like / Dislike poll',
  quiz: 'Quiz',
  quizOneQuestion: 'Quiz one question',
  sport24: 'Sport table',
  topic: 'Topic',
  recipe: 'Recipe',
  video: 'Video',
  gallery: 'Gallery',
  redirect: 'Redirect',
  hottopics: 'Hot Topics',
  breakingnews: 'Breaking News',
  rightNow: 'Right now',
  newsOfTheDay: 'News of the day',
  embeddedApplication: 'VLM applications',
  menuEditor: 'Menu editor'
}

export default messages
