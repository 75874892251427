const messages = {
  id: 'Id',
  title: 'Title',
  rubrics: 'Rubrics',
  search: 'Search',
  mainProduct: 'Main product',
  loading_err: 'Error loading products'
}

export default messages
