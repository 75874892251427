const messages = {
  error_checking_gap: 'Error checking position',
  importance: 'Importance',
  time: 'Time',
  backwards: 'Backwards',
  forwards: 'Forwards',
  refresh: 'Refresh',
  now: 'Now',
  position: 'Position',
  add_article: 'Add article',
  add_video: 'Add video',
  copy_article: 'Copy article',
  insert_article: 'Duplicate article',
  insert_video: 'Duplicate video',
  interval_1: '15 minutes',
  interval_2: '30 minutes',
  interval_3: '1 hour',
  interval_4: '4 hours',
  delete_modal_header: 'Delete this record ?',
  delete_modal_text: 'Are you sure you want to delete this record?',
  delete_modal_no: 'No',
  delete_modal_yes: 'Yes',
  create_modal_header: 'Add an article',
  create_video_modal_header: 'Add a video',
  info_modal_header: 'Info',
  article_not_found: 'The article was not found or is in draft status.',
  video_not_found: 'Video not found.',
  gap_busy: 'The position in the given interval does not have free space for another article.',
  article_status_warning: 'The article is currently not published, do you still want to schedule it?',
  filter: {
    site: 'Site',
    visible_blocks: 'Visible blocks',
    reset: 'Reset filter'
  },
  status: 'Status',
  type: 'Type',
  internal: 'Internal',
  external: 'External',
  published_since: 'Published since',
  published_until: 'Published until',
  show_until: 'Show Until',
  article_document_id: 'Article document id',
  article_info: 'Article info',
  video_info: 'Video info',
  image: 'Image',
  video: 'Video',
  video_id: 'Video ID',
  title: 'Title',
  ctr_info: 'average CTR for the position during last 7 days',
  alternative_title: 'Alternative title',
  article: {
    status: 'Stav',
    title: 'Title',
    url: 'URL',
    first_paragraph: 'First paragraph',
    site_name: 'Site name',
    site_url: 'Site url',
    rubric_name: 'Rubric name',
    rubric_url: 'Rubric url',
    author: 'Author',
    image: 'Image',
    flags: 'Flags',
    rubric: 'Rubric',
    order_date: 'Published date',
    published_since: 'Publikované od',
    published_until: 'Publikované do'
  },
  freshness: 'Freshness',
  realUsers: 'Real Users',
  avgTimeSpent: 'Average time spent'
}

export default messages
