const messages = {
  create_menu_label: 'Create menu',
  notify: {
    menu_created: 'Menu configuration was successfully created',
    required_fields: 'Please fill in all required fields'
  },
  form: {
    server: 'Server',
    menu_name: 'Menu name',
    state: 'State'
  },
  type_value: {
    active: 'Active',
    inactive: 'Inactive'
  },
  switch: {
    shown: 'Shown'
  },
  list: {
    id: 'ID',
    server: 'Server',
    menu_name: 'Menu name'
  },
  itemmodal: {
    title_add: 'Add new menu item',
    title_edit: 'Edit menu item',
    pinboard_title_add: 'Add new pinboard item',
    pinboard_title_edit: 'Edit pinboard item',
    menu_structure_label: 'Location in structure',
    menu_pinboard_label: 'Location in Pinboard',
    menu_structure_menu: 'Menu (main category)',
    title_label: 'Menu item name',
    name_label: 'Help (Title)',
    accesskey_label: 'Shortcut',
    type_label: 'Type of link',
    rubric_label: 'Rubric',
    tag_label: 'Editorial tag',
    geneea_tag_label: 'Geneea tag',
    special_label: 'Special',
    theme_label: 'Theme',
    series_label: 'Series',
    article_label: 'Article',
    article_placeholder: 'Start typing the name/title of the article',
    url_label: 'URL',
    displayrestriction_label: 'Restrict to time',
    displayfrom_label: 'Display from',
    displayto_label: 'Display to'
  },
  pinboard: {
    main_menu: 'Pinboard in main menu',
    popup: 'Pinboard Popup',
    both: 'Both Pinboards'
  },
  menu: 'Menu',
  pinboard_title: 'Pinboard',
  add_item: 'Add item',
  column: 'Column',
  drag_label: 'Drag',
  modal_copy: {
    title: 'Create menu copies',
    text: 'Do you want to create a copy of the selected menu configuration?'
  },
  type: {
    rubric: 'Rubric',
    tag: 'Tag - editorial',
    geneeaTag: 'Tag - Geneea',
    specialArticleGroup: 'Special',
    theme: 'Theme',
    series: 'Series',
    article: 'Article',
    url: 'Link',
    level_2: '2. level',
    level_3: '3. level'
  },
  filter: {
    server: 'Server',
    menu_name: 'Menu name',
    state: 'State',
    filter_label: 'Filter'
  },
  linktype: {
    rubric: 'Rubric',
    tag: 'Editorial tag',
    geneeaTag: 'Geneea tag',
    specialArticleGroup: 'Special',
    theme: 'Theme',
    series: 'Series',
    article: 'Article',
    url: 'Libovolná URL'
  }
}

export default messages
